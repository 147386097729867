import { formatDateHour, makeCommas } from "../services/utils"

export function ShowCard({ show, stream }) {

    const getColor = () => {
        if (show.status === 'pending') return 'yellow'
        if (show.status === 'approved') return 'green'
        if (show.status === 'started') return '#F37F13'
        if (show.status === 'over') return 'white'
        return 'red'
    }

    return <div className="show-card">
        <img className="thumbnail" src={show.img} alt={show.title} />
        <div className="show-info">
            <h1>{show.title}</h1>
            <p>{show.shortDescription}</p>
            <p>Price : {show.price}₪</p>
            <p>Tickets sold : {makeCommas(show.ticketsAmount)}</p>
            <p>Estimated revenue : {makeCommas(show.price * show.ticketsAmount)}</p>
            <p>Starts at : {formatDateHour(show.date)}</p>
            <p>status : <span style={{ color: getColor() }}>{show.status}</span></p>
        </div>
        <div className="button-wrapper">
            <div className="stream-button" style={{ visibility: (show.status === 'approved' || show.status === 'started') ? 'visible' : 'hidden' }} onClick={() => stream(show)}>
                START
                <span className="material-symbols-outlined">play_circle</span>
            </div>
        </div>
    </div>
}