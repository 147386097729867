import { httpService } from './http.service.js'

export const adminService = {
    getAllCreators,
    setCreatorStatus,
    getShows,
    setShowStatus,
    getHistory,
    cancelShow
}

async function getAllCreators() {
    const creators = await httpService.get('handle-admin/get-all-creators')
    return creators
}

async function setCreatorStatus(phone, status) {
    const name = await httpService.post('handle-admin/set-creator-status/' + phone, { status })
    return name
}

async function getShows(status) {
    const shows = await httpService.get('handle-admin/get-shows/' + status)
    return shows
}

async function setShowStatus(showId, status) {
    const confirm = await httpService.post('handle-admin/set-show-status/' + showId, { status })
    return confirm
}

async function getHistory(from = 0) {
    const shows = await httpService.get('handle-admin/get-history/' + from)
    return shows
}

async function cancelShow(showId) {
    const confirm = await httpService.post('handle-admin/cancel-show/' + showId)
    return confirm
}

