import { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { NavLink, useLocation, useNavigate } from "react-router-dom"
import { toggleMenu, setPopup } from "../store/actions/general.actions"

export function Header() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()
    const creator = useSelector((state) => state.creator)
    const phones = [972528087084, 972509620963, 972503843964, 972524041163]
    const admin = creator.phone && phones.includes(creator.phone)

    const { registerPhase } = useSelector((state) => state.tutorialModule)
    let isMobile = window.innerWidth < 930 ? true : false

    const navTo = (path) => {
        if (!location.pathname.includes('stream-control')) navigate(path)
        else dispatch(setPopup(path))
    }

    return <>
        {!isMobile && <div className="header noselect" style={{ zIndex: registerPhase === 2 ? '1001' : '100' }}>
            <div className="header-options">
                <span className="material-symbols-outlined menu-icon clickable hover-main" onClick={() => dispatch(toggleMenu())}>menu</span>
                {admin && <img className="admin" src={require('../style/imgs/admin.png')} onClick={() => navTo('/admin')} />}
            </div>
            <img onClick={() => { navTo('/') }} className="logo clickable" src={require('../style/imgs/logo.png')} />
            <div className="header-options" style={{ visibility: (creator) ? 'visible' : 'hidden' }}>
                <div onClick={() => navTo('/create')} className="create-button">Create</div>
            </div>

        </div>}
        {(isMobile && !location.pathname.includes('stream-control')) && <>
            <div className="header" style={{ zIndex: registerPhase === 2 ? '1001' : '5' }}>
                {admin && <img className="admin" src={require('../style/imgs/admin.png')} onClick={() => navTo('/admin')} />}
                <NavLink to='/'><img className="logo" src={require('../style/imgs/logo.png')} /></NavLink>
                <span className="material-symbols-outlined menu-icon clickable hover-main" onClick={() => dispatch(toggleMenu())}>menu</span>
            </div>
            <nav className='footer-mobile' style={{ zIndex: registerPhase === 2 ? '1001' : '1' }}>
                {creator.phone && <img src={require('../style/imgs/create.png')} onClick={() => navTo('/create')} />}
                <NavLink to='/'><img src={require(`../style/imgs/home-icon-dark.png`)} /></NavLink>
            </nav>
        </>}
    </>
}