import { useEffect } from "react"
import { NavLink } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import { logout } from "../store/reducers/creator.reducer"
import { setMenu, setPopup } from "../store/actions/general.actions"
import { useNavigate } from "react-router-dom"

export function Menu() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { menu } = useSelector((storeState) => storeState.generalModule)
    const creator = useSelector(state => state.creator)
    const isMobile = window.innerWidth < 700

    useEffect(() => {
        if (menu && isMobile) document.body.style.overflowY = 'hidden'
        else document.body.style.overflowY = 'auto'
        return () => document.body.style.overflowY = 'auto'
    }, [menu])

    const logOut = () => {
        try {
            dispatch(logout())
            navigate('/login')
        }
        catch {
            console.log('could not log out')
        }
    }

    if (menu === '') return <></>

    return (<>
        <div className="screen" onClick={() => dispatch(setMenu(''))}></div>
        <section className='menu noselect'>
            {menu === 'normal' && <>
                <div className="hover-main" onClick={() => dispatch(setMenu('help'))}><span className="material-symbols-outlined">help</span><div>Help</div></div>
                <div className="hover-main" onClick={() => { dispatch(setPopup('support')); dispatch(setMenu('')) }}><span className="material-symbols-outlined">support_agent</span><div>Support</div></div>
                {creator.phone ? <div onClick={() => { logOut(); dispatch(setMenu('')) }} className="hover-main"><NavLink to='/'><span className="material-symbols-outlined">logout</span> <div>Log out</div></NavLink></div> :
                    <div className="hover-main" onClick={() => { dispatch(setMenu('')) }}><NavLink className="main-color" to='/profile'><span className="material-symbols-outlined">login</span><div>Login</div></NavLink></div>}
                {isMobile && <div onClick={() => dispatch(setMenu(''))} className="close-mobile clickable"><span className="material-symbols-outlined">cancel</span></div>}
            </>}
            {menu === 'help' && <>
                <div className='center-start'>
                    <p className="help-p">Help</p>
                    <div className={`help-opt hover-main`} onClick={() => window.open('https://homeric-games.gitbook.io/cvc/metamask-full-guide', '_blank').focus()}><p>how to install metamask?</p><span className="material-symbols-outlined">chevron_right</span></div>
                    <div className={`help-opt hover-main`} onClick={() => window.open('https://homeric-games.gitbook.io/cvc/add-funds-to-metamask', '_blank').focus()}><p>how to add money to the meta mask?</p><span className="material-symbols-outlined">chevron_right</span></div>
                    <div className={`help-opt hover-main`}><p>how to buy a ticket?</p><span className="material-symbols-outlined">chevron_right</span></div>
                    <div className={`help-opt hover-main`} onClick={() => window.open('https://homeric-games.gitbook.io/cvc/i-bought-a-ticket-what-now', '_blank').focus()}><p>I bought a ticket what now?</p><span className="material-symbols-outlined">chevron_right</span></div>
                    <div className={`help-opt hover-main`} onClick={() => window.open('https://homeric-games.gitbook.io/cvc/how-to-add-bnb-network-to-metamask', '_blank').focus()}><p>how to add my network to metamask?</p><span className="material-symbols-outlined">chevron_right</span></div>
                    <div className={`help-opt hover-main`} onClick={() => window.open('https://homeric-games.gitbook.io/cvc/metamask-full-guide', '_blank').focus()}><p>general help in the platform.</p><span className="material-symbols-outlined">chevron_right</span></div>
                </div>
                <div className="close" onClick={() => dispatch(setMenu('normal'))}><span className="material-symbols-outlined">close</span></div>
            </>}
        </section>
    </>)
}