import { useLocation } from "react-router-dom"

export function Footer() {
    const location = useLocation()
    
    if (location.pathname.includes('stream-control')) return <></>

    return <div className='footer'>
        <p onClick={() => window.open('https://homericentertainment.com/', '_blank').focus()}>®2024 Homeric entertainment</p>
        <p onClick={() => window.open('https://www.privacypolicyonline.com/live.php?token=J4MpfGXa6se1Ak9047TKVsyJzqtXRHjD', '_blank').focus()}>privacy policy</p>
        <p onClick={() => window.open('https://www.privacypolicyonline.com/live.php?token=BSvr42ubTJcEKnEu7WdkM3qMJ7Dozlla', '_blank').focus()}>terms of service</p>
        <section className="contact">
            <p>join PikmeTV community :</p>
            <a href="https://www.instagram.com/pikme.tv/" target="_blank"><img src={require('../style/imgs/contact/instagram.png')} /></a>
            <a href="https://twitter.com/PikmeTv" target="_blank"><img src={require('../style/imgs/contact/twitter.png')} /></a>
            <a href="https://www.tiktok.com/@pikme.tv?lang=he-IL" target="_blank"><img src={require('../style/imgs/contact/tiktok.png')} /></a>
        </section>
    </div>

}
