import { useState, useEffect } from "react"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router"
import { adminService } from "../services/admin.service"
import { Error } from './error'
import { ControlWaiting } from "../cmps/control-waiting"
import { ControlCurrent } from "../cmps/control-current"
import { ControlHistory } from "../cmps/control-history"
import { ControlCreators } from "../cmps/control-creators"
import { Loader } from "../cmps/loader"
const options = ['waiting', 'upcoming', 'history', 'creators']

export function Admin() {
    const navigate = useNavigate()
    const [opt, setOpt] = useState('waiting')
    const creator = useSelector((state) => state.creator)

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    if (options === 'loading') return <div className="home"><Loader /></div>

    if (!options) {
        navigate('/')
        return <h1>Unauthorized</h1>
    }

    try {
        return <section className="control">
            <div className="control-banner"><h1>Welcome {creator.nickName}</h1></div>
            <div className="options-bar">
                {options.map(op => <p className={op === opt ? 'chosen' : ''} key={op} onClick={() => setOpt(op)}>{op.charAt(0).toUpperCase() + op.slice(1)}</p>)}
            </div>
            {opt === 'waiting' && <ControlWaiting />}
            {opt === 'upcoming' && <ControlCurrent />}
            {opt === 'history' && <ControlHistory />}
            {opt === 'creators' && <ControlCreators />}
        </section>
    }
    catch {
        return <Error />
    }
}