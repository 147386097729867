import { useState, useRef } from "react"
import { useEffect } from "react"
import { useSelector, useDispatch } from 'react-redux'
import { io } from "socket.io-client"
import { setUpperPopup, setViewers } from '../store/actions/general.actions'
import { isMobile } from "react-device-detect"
const socket = process.env.NODE_ENV === 'production' ? io.connect('https://server.pikme.tv') : io.connect('http://localhost:3030')
const colors = ["blue", "cyan", "magenta", "lime", "maroon", "navy", "olive", "teal", "violet", "silver", "gold", "indigo", "coral", "crimson", "fuchsia", "khaki", "lavender", "plum", "turquoise", "wheat", "beige", "azure", "aliceblue", "antiquewhite", "aquamarine", "bisque", "blanchedalmond", "blueviolet", "burlywood", "cadetblue", "chartreuse", "chocolate", "cornflowerblue", "cornsilk", "darkblue", "darkcyan", "darkgoldenrod", "darkgray", "darkgreen", "darkgrey", "darkkhaki", "darkmagenta", "darkolivegreen", "darkorange", "darkorchid", "darkred", "darksalmon", "darkseagreen", "darkslateblue", "darkslategray", "darkslategrey", "darkturquoise", "darkviolet", "deeppink", "deepskyblue", "dimgray", "dimgrey", "dodgerblue", "firebrick", "floralwhite", "forestgreen", "gainsboro", "ghostwhite", "gold", "goldenrod", "greenyellow", "honeydew", "hotpink", "indianred", "ivory", "lavenderblush", "lawngreen", "lemonchiffon", "lightblue", "lightcoral", "lightcyan", "lightgoldenrodyellow", "lightgray", "lightgreen", "lightgrey", "lightpink", "lightsalmon", "lightseagreen", "lightskyblue", "lightslategray", "lightslategrey", "lightsteelblue", "lightyellow", "limegreen", "linen", "mediumaquamarine", "mediumblue", "mediumorchid", "mediumpurple", "mediumseagreen", "mediumslateblue", "mediumspringgreen", "mediumturquoise", "mediumvioletred", "midnightblue", "mintcream", "mistyrose", "moccasin", "navajowhite", "oldlace", "ol"]

const StreamChat = ({ eventName, mobile, zIndex, end, cameraIdx, cameras, start }) => {
    const dispatch = useDispatch()
    const creator = useSelector(state => state.creator)
    const [messages, setMessages] = useState([])
    const [showChat, setShowChat] = useState(true)
    const [menu, setMenu] = useState('')
    const [name, setName] = useState('')
    const [position, setPosition] = useState(0)
    const banRef = useRef()
    const timeRef = useRef()

    let nickName = (creator && creator.nickName) ? creator.nickName : "Guest" + Math.floor(Math.random() * 10000)
    let randomColor = colors[nickName.charCodeAt(0) % colors.length] || 'white'

    useEffect(() => {
        if (socket && creator) socket.emit('joinRoom', { username: creator.nickName, roomName: eventName, userId: String(creator.phone) })
        return () => socket.off("message")
    }, [])

    useEffect(() => {
        document.getElementById('body-text').scrollTop = document.getElementById('body-text').scrollHeight
    }, [messages])

    useEffect(() => {
        if (start) socket.emit('start-stream', eventName)
    }, [start])

    const onEnter = (ev) => {
        if (ev.key === "Enter") sendMessage()
    }

    socket.on('message', (message) => {
        if (message.viewers) dispatch(setViewers(message.viewers))
        if ((message.message !== 'viewers-update' || message.nickName !== 'PikmeTV')) {
            if (message.newRoom) setMessages([message])
            else setMessages([...messages, message])
        }
    })

    const sendMessage = () => {
        const message = document.getElementById('input').value
        if (message === "") return
        if (socket) socket.emit('chat', { nickName: nickName, message: message, color: randomColor, roomId: eventName, image: creator.image })
        document.getElementById('input').value = ''
        document.getElementById('input').focus()
        document.getElementById('body-text').scrollTop = document.getElementById('body-text').scrollHeight
    }

    const prevent = (e) => {
        e.preventDefault()
    }

    const muteUser = () => {
        socket.emit('mute', { userId: menu, time: timeRef.current.value, adminPhone: creator.phone })
        dispatch(setUpperPopup('muted'))
        setMenu('')
    }

    const openMenu = (menu, name, el) => {
        setMenu(menu)
        setName(name)
        const rect = el.getBoundingClientRect()
        const top = rect.top + window.scrollY - 30
        if (top + 300 > window.innerHeight) setPosition((top - 200) + 'px')
        else setPosition(top + 'px')
    }

    if (cameras && cameras.length > 0) {
        let type = 'normal'
        // if (cameras[cameraIdx].label.toLowerCase().includes('back')) type = 'back'
        // if (cameras[cameraIdx].label.toLowerCase().includes('front')) type = 'front'
        socket.emit('change-camera', { type, roomId: eventName })
    }

    if (end) socket.emit('end-event', eventName)

    return <>
        {(mobile && !showChat) ?
            <div className="chat-bar-mobile" style={{ zIndex }}>
                <span className="material-symbols-outlined" >settings</span>
                <p>Live chat</p>
                <span onClick={() => setShowChat(true)} className="material-symbols-outlined">expand_less</span>
            </div> :
            <div className="chat-box" style={{ zIndex }}>
                <div className="chat-header">
                    <h1>Live chat</h1>
                </div>

                <div id="body-text" className="body-text" >
                    {messages.map((message, index) => <div key={index} className="message-div" onClick={(ev) => openMenu(message.userId, message.nickName, ev.target)}>
                        <span style={{ color: message.color }}>{message.nickName}</span>
                        <span style={{ marginLeft: "1px" }} >:</span>
                        <span style={{ marginLeft: "5px", fontWeight: "lighter" }} >{message.message}</span>
                    </div>)}
                </div>

                {menu && <div className="ban-menu" style={{ top: isMobile ? '20%' : position || '40%' }}>
                    <div className="name">{name}</div>
                    <div className="selects">
                        <p>Mute : </p>
                        <select ref={timeRef}>
                            <option value="60">1 minute</option>
                            <option value="600">10 minutes</option>
                            <option value="1800">30 minutes</option>
                            <option value="3600">1 hour</option>
                            <option value="86400">1 day</option>
                            <option value="604800">1 week</option>
                        </select>
                    </div>
                    <div className="buttons">
                        <div onClick={muteUser}>Confirm</div>
                        <div onClick={() => setMenu('')}>Cancel</div>
                    </div>
                </div>}

                <form className="chat-box-input" onSubmit={prevent}>
                    <input id="input" type="text" onKeyDown={onEnter} placeholder="Type a message" />
                    <input type='submit' style={{ width: '0', padding: '0', borderWidth: '0', visibility: 'hidden' }} />
                    <button style={{ width: '0', padding: '0', borderWidth: '0', visibility: 'hidden' }}></button>
                </form>
            </div>}
    </>
}

export default StreamChat