import { useState, useEffect, useRef } from "react"
import { useDispatch } from "react-redux"
import { adminService } from "../services/admin.service"
import { setPopup } from "../store/actions/general.actions"
import { Error } from "../pages/error"
import { formatDateHour } from "../services/utils"

export function ControlHistory() {
    const dispatch = useDispatch()
    const [history, setHistory] = useState([])
    const [from, setFrom] = useState(0)
    const [error, setError] = useState(false)
    const [loader, setLoader] = useState(true)
    const targetRef = useRef(null)
    const debounce = useRef(false)

    useEffect(() => {
        window.addEventListener('scroll', loadMore)
        return () => window.removeEventListener('scroll', loadMore)
    }, [])

    useEffect(() => {
        loadHistory(from)
    }, [from])

    const loadHistory = async () => {
        try {
            const events = await adminService.getHistory(from)
            setHistory(history.concat(events))
            if (events.length < 10) setLoader(false)
        }
        catch {
            setError(true)
        }
    }

    const loadMore = () => {
        if (debounce.current) return
        if (!targetRef.current) {
            window.removeEventListener('scroll', loadMore)
            return
        }
        const rect = targetRef.current.getBoundingClientRect()
        if (rect.top >= 0 && rect.bottom <= (window.innerHeight || document.documentElement.clientHeight)) {
            debounce.current = true
            setTimeout(() => { debounce.current = false }, 800)
            setFrom((from) => from + 10)
        }
    }

    if (error) return <Error />

    try {
        return <div className="control-current control-history">
            <p className="list-count">Shows log : <span>{history.length}</span></p>
            {history.length > 0 && <table >
                <thead>
                    <tr>
                        <td>Creator</td>
                        <td>Title</td>
                        <td>Date</td>
                        <td>Revenew</td>
                        <td>Thumbnail</td>
                        {/* <td>Actions</td> */}
                    </tr>
                </thead>
                <tbody style={{ zIndex: '1001' }}>
                    {history.map((show, idx) => <tr key={idx}>
                        <td>{show.miniCreator.nickName + `(${show.miniCreator.phone})`}</td>
                        <td>{show.title}</td>
                        <td>{formatDateHour(show.date)}</td>
                        <td>{show.status === 'cancelled' ? '0 (cancelled)' : show.price * show.ticketsAmount}</td>
                        <td className="thumbnail"><img src={show.img} onClick={() => dispatch(setPopup(show.img))} /></td>
                        {/* <td className="actions">
                            <button className="accept" onClick={() => decideStatus(show._id, 'approved')}>Accept</button>
                            <button className="reject" onClick={() => decideStatus(show._id, 'rejected')}>Reject</button>
                        </td> */}
                    </tr>)}
                </tbody>
            </table>}
            {loader && <div style={{ margin: 'auto', position: 'relative' }}><div ref={targetRef} className="loader loader-block"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div></div>}
        </div>

    }
    catch {
        return <Error />
    }
}