import { useEffect } from "react"
import { useNavigate } from "react-router"
import { useSelector, useDispatch } from "react-redux"
import { setPopup } from "../store/actions/general.actions"
import { isMobile } from "react-device-detect"
import { Loader } from "./loader"
import { PopupSupport } from "./popup-support"

export function Popup() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { popup } = useSelector((storeState) => storeState.generalModule)

    useEffect(() => {
        if (popup) document.body.classList.add("no-scroll")
        else document.body.classList.remove("no-scroll")
        return () => document.body.classList.remove("no-scroll")
    }, [popup])

    if (!popup) return <></>

    if (popup.charAt(0) === '/') return <>
        <div className="screen blur" onClick={() => { dispatch(setPopup('')) }}>
            {isMobile && <div onClick={() => dispatch(setPopup(''))} className="popup-close-mobile"><p>Tap to close</p></div>}
        </div>
        <div className="confirm-exit">
            <img src={require(`../style/imgs/stream/pause.png`)} />
            <h1>Confirm navigation</h1>
            <p>If you are already live, this action will pause your stream, continue anyway?</p>
            <div>
                <div onClick={() => dispatch(setPopup(''))}>Cancel</div>
                <div className="action" onClick={() => { dispatch(setPopup('')); navigate(popup) }}>Continue</div>
            </div>
        </div></>

    return <>
        <div className="screen blur" onClick={() => { dispatch(setPopup('')) }}>
            {isMobile && <div onClick={() => dispatch(setPopup(''))} className="popup-close-mobile"><p>Tap to close</p></div>}
        </div>
        <section className='popup' style={{ zIndex: '100' }}>
            {popup === 'upload-event' && <div>
                <p style={{ textAlign: 'center' }}>Uploading your event, this may take up to a minute...</p>
                <Loader moreClasses='loader-block' />
            </div>}

            {popup === 'support' && <PopupSupport />}

            {popup === 'created-show' && <div className="created">
                <img src={require('../style/imgs/share/vi.png')} />
                <h1>Show in progress</h1>
                <p>The show was successfully created and is now awaiting approval.</p>
                <div className="done" onClick={() => dispatch(setPopup(''))}>Done</div>
            </div>}

            {(popup.startsWith('https://res.cloudinary.com/') || popup.startsWith('https://images')) && <img className="thumbnail-popup" src={popup} />}

        </section>
    </>
}