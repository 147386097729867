import './style/main.scss'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { login } from './store/reducers/creator.reducer'
import { creatorService } from './services/creator.service'
import { HashRouter as Router, Route, Routes } from 'react-router-dom'

//pages
import { Home } from './pages/home'
import { StreamWrapper } from './pages/stream-wrapper'
import { Admin } from './pages/admin'
import { Register } from './pages/register'
import { Create } from './pages/create'
import { Ban } from './pages/ban'
import { Login } from './pages/login'

//cmps
import { Header } from './cmps/header'
import { Footer } from './cmps/footer'
import { Menu } from "../src/cmps/menu"
import { Popup } from "../src/cmps/popup"
import { UpperPopup } from "../src/cmps/upper-popup"

function App() {
  const dispatch = useDispatch()

  useEffect(() => {
    getCreator()
  }, [])

  const getCreator = async () => {
    try {
      const loadedCreator = await creatorService.getCreator()
      if (loadedCreator) dispatch(login(loadedCreator))
    }
    catch (err) {
      console.log(err)
    }
  }

  return <Router>
    <div className="app">
      <Header />
      <main className='main-layout'>
        <Routes>
          <Route path='/stream-control' element={<StreamWrapper />} />
          <Route path='/create' element={<Create />} />
          <Route path='/admin' element={<Admin />} />
          <Route path='/login' element={<Login />} />
          <Route path='/register' element={<Register />} />
          <Route path='/ban' element={<Ban />} />
          <Route path='/' element={<Home />} />
        </Routes>
      </main>
      <Footer />
    </div>
    <Menu />
    <Popup />
    <UpperPopup />
  </Router>

}

export default App