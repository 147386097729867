import { useState, useEffect, useRef, useMemo } from "react"
import { useDispatch } from "react-redux"
import { adminService } from "../services/admin.service"
import { Error } from "../pages/error"
import { formatDateHour } from "../services/utils"
import { setUpperPopup } from "../store/actions/general.actions"
import { Loader } from "./loader"
const options = ['all', 'pending', 'banned']

export function ControlCreators() {
    const dispatch = useDispatch()
    const [creators, setCreators] = useState([])
    const [filter, setFilter] = useState('all')
    const [error, setError] = useState(false)
    const [rerender, setRerender] = useState(0)
    const searchRef = useRef(null)

    useEffect(() => {
        loadAllCreators()
    }, [])

    const data = useMemo(() => {
        if (!creators) return []
        if (filter === 'all') return creators
        if (filter === 'pending') return creators.filter(c => c.status === 'pending')
        if (filter === 'banned') return creators.filter(c => c.status === 'banned')
        if (filter === 'byName') return creators.filter(c => c.nickName.toLowerCase().includes(searchRef.current.value.toLowerCase()))
        if (filter === 'byPhone') return creators.filter(c => String(c.phone).includes(searchRef.current.value))
    }, [creators, filter, rerender])

    const loadAllCreators = async () => {
        try {
            const loadedAll = await adminService.getAllCreators()
            setCreators(loadedAll)
        }
        catch {
            setError(true)
        }
    }

    const setCreatorStatus = async (creator, status) => {
        try {
            await adminService.setCreatorStatus(creator.phone, status)
            const all = creators.map(c => c.phone === creator.phone ? { ...c, status } : c)
            setCreators(all)
            dispatch(setUpperPopup('statusChanged'))
        }
        catch {
            dispatch(setUpperPopup('errorServer'))
        }
    }

    const searchCreator = async (e) => {
        e.preventDefault()
        if (!searchRef.current.value) return
        setRerender(rerender + 1)
        if (isNaN(Number(searchRef.current.value))) return setFilter('byName')
        setFilter('byPhone')
    }

    if (error) return <Error />

    if (!creators.length) return <Loader />

    try {
        return <div className="control-current control-history">
            <p className="list-count">Creators : <span>{data.length}</span></p>
            <div className="upper-creators">
                <div className="options-bar">
                    {options.map(op => <p className={op === filter ? 'chosen' : ''} key={op} onClick={() => setFilter(op)}>{op.charAt(0).toUpperCase() + op.slice(1)}</p>)}
                </div>
                <form className="creator-search" onSubmit={searchCreator}>
                    <input ref={searchRef} placeholder="Phone or name" />
                    <button className="search"><span className="material-symbols-outlined">search</span></button>
                </form>
            </div>

            {data.length ? <table>
                <thead>
                    <tr>
                        <td>Name</td>
                        <td>Phone</td>
                        <td>Joined since</td>
                        <td>status</td>
                        <td>Followers</td>
                        <td>Social</td>
                        <td>Actions</td>
                    </tr>
                </thead>
                <tbody style={{ zIndex: '1001' }}>
                    {data.map((creator, idx) => <tr key={idx}>
                        <td>{creator.nickName}</td>
                        <td>{creator.phone}</td>
                        <td>{formatDateHour(creator.createdAt)}</td>
                        <td>{creator.status}</td>
                        <td>{creator.followersAmount}</td>
                        <td className="social">
                            {creator.socialLinks.youtube && <a href={creator.socialLinks.youtube} target="_blank" rel="noreferrer"><img src={require('../style/imgs/contact/youtube.png')} /></a>}
                            {creator.socialLinks.instagram && <a href={creator.socialLinks.instagram} target="_blank" rel="noreferrer"><img src={require('../style/imgs/contact/instagram.png')} /></a>}
                            {creator.socialLinks.tiktok && <a href={creator.socialLinks.tiktok} target="_blank" rel="noreferrer"><img src={require('../style/imgs/contact/tiktok.png')} /></a>}
                        </td>
                        <td className="actions">
                            {creator.status === 'banned' && <button className="unban" onClick={() => setCreatorStatus(creator, 'approved')}>Unban</button>}
                            {creator.status === 'approved' && <button className="ban" onClick={() => setCreatorStatus(creator, 'banned')}>Ban</button>}
                            {creator.status === 'rejected' && <button className="accept" onClick={() => setCreatorStatus(creator, 'approved')}>Accept</button>}
                            {creator.status === 'pending' && <>
                                <button className="accept" onClick={() => setCreatorStatus(creator, 'approved')}>Accept</button>
                                <button className="reject" onClick={() => setCreatorStatus(creator, 'rejected')}>Reject</button>
                            </>}
                        </td>
                    </tr>)}
                </tbody>
            </table> :
                <p className="not-found" style={{ marginLeft: '10px' }}>No creators found</p>}
        </div>
    }
    catch (err) {
        console.log(err)
        return <Error />
    }
}