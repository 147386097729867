import { useState, useEffect } from "react"
import { useDispatch } from "react-redux"
import { adminService } from "../services/admin.service"
import { Error } from "../pages/error"
import { formatDateHour } from "../services/utils"
import { Loader } from "./loader"
import { setUpperPopup, setPopup } from "../store/actions/general.actions"

export function ControlWaiting() {
    const dispatch = useDispatch()
    const [pendingShows, setPendingShows] = useState([])
    const [error, setError] = useState(false)
    const [loader, setLoader] = useState(true)

    useEffect(() => {
        loadPendingShows()
    }, [])

    const loadPendingShows = async () => {
        try {
            setLoader(true)
            const pendings = await adminService.getShows('pending')
            setPendingShows(pendings)
            setLoader(false)
        }
        catch {
            setError(true)
        }
    }

    const decideStatus = async (id, status) => {
        try {
            await adminService.setShowStatus(id, status)
            setPendingShows(pendingShows.filter(show => show._id !== id))
        }
        catch {
            dispatch(setUpperPopup('errorServer'))
        }
    }

    if (error) return <Error />

    if (loader) return <Loader />

    try {
        return <div className="control-current control-history">
            <p className="list-count">Pending shows : <span>{pendingShows.length}</span></p>
            {pendingShows.length > 0 && <table >
                <thead>
                    <tr>
                        <td>Creator</td>
                        <td>Title</td>
                        <td>Starts at</td>
                        <td>Price</td>
                        <td>Thumbnail</td>
                        <td>Actions</td>
                    </tr>
                </thead>
                <tbody style={{ zIndex: '1001' }}>
                    {pendingShows.map((show, idx) => <tr key={idx}>
                        <td>{show.miniCreator.nickName + `(${show.miniCreator.phone})`}</td>
                        <td>{show.title}</td>
                        <td>{formatDateHour(show.date)}</td>
                        <td>{show.price}</td>
                        <td className="thumbnail"><img src={show.img} onClick={()=>dispatch(setPopup(show.img))}/></td>
                        <td className="actions">
                            <button className="accept" onClick={() => decideStatus(show._id, 'approved')}>Accept</button>
                            <button className="reject" onClick={() => decideStatus(show._id, 'rejected')}>Reject</button>
                        </td>
                    </tr>)}
                </tbody>
            </table>}
        </div>
    }
    catch (err) {
        return <Error />
    }
}